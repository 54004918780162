import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { lazy, Suspense } from "react";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import $ from "jquery";

// common
const PageNotFound = lazy(() => import("./components/common/PageNotFound"));
const HomePage = lazy(() => import("./components/common/home/HomePage"));

function App() {
  const auth = () => {
    if (sessionStorage.getItem("session")) {
      return true;
    } else if (localStorage.getItem("session")) {
      var sessionDate = JSON.parse(localStorage.getItem("session")).date;
      var diff = moment().startOf("day").diff(moment(sessionDate).startOf("day"), "days");
      if (diff >= 0 && diff < 1) {
        return true;
      } else {
        // sessionStorage.clear();
        localStorage.clear();
        return false;
      }
    } else {
      sessionStorage.clear();
      // localStorage.clear();
      return false;
    }
  };

  const body = document.querySelector("body");
  body?.style.setProperty("--screen-height", $(window).height() + "px");
  

  // $(document).ready(function () {
  //   $(".usp_wrap .usp_wrap_inner").click(function () {
  //     if (screen.width <= 1200) {
  //       $(".usp_wrap_inner_desc").removeClass("active");
  //       $(".usp_wrap_inner").find(".usp_desc").slideUp(300);
  //       $(this).find(".usp_desc").slideToggle(300);
  //     }
  //   });
  // });

  $(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
      $(".common_head").addClass("fixed-me");
      $(".black_logo").css("display", "block");
      $("#nav-logo-id").css("display", "none");
    } else {
      $(".common_head").removeClass("fixed-me");
      $("#nav-logo-id").css("display", "block");
      $(".black_logo").css("display", "none");
    }
  });

  // $(document).ready(function () {
  //   $("#request_demo").click(function () {
  //     $(".subscribe_block").toggleClass("show");
  //   });
  // });

  const isAuthenticated = auth();

  return (
    <div className="App">
      <Suspense
        fallback={
          <center style={{ paddingTop: "40vh" }}>
            <Spinner animation="border" as="span" variant="info" style={{ height: 100, width: 100 }} />
            <h4 className="pt-5">Loading...</h4>
          </center>
        }>
        <Router>
          <Switch>
            {/* user */}

            {/* common */}
            <Route exact path="/" component={HomePage} />

            <Route exact path="/PageNotFound" component={PageNotFound} />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
